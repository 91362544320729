import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits } from 'ethers'

import { setAccountSignOn, setLoginWallet } from "../../../redux/Account/actions";
import {axiosInstance } from "../../../utils";
import {validateEmailText, sendWaitroomEmailCode, checkLoginEmailCode, sendWaitroomWalletCheck } from "../../../utils/signon";

import $ from "jquery";
import { executionAsyncId } from "async_hooks";

import { GameLoginPage, GameLogoutPage, GamePaymentPage, GameHomePage, GameRegisterPage } from "./Sections";

let current_user_id = 0; 
let current_session_id = '';
let current_wallet_address = '';
let current_email_address = '';
let current_user_name = '';
let current_game_id = 1;
let current_game_name = 'EXR GHOST RIDERS';
let current_app_id = 0;
let current_product_id = '';
let current_game_action = '';
let login_transaction = {};
let payment_transaction = {};
export const GameWidget = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { accountID, accountAddress } = useSelector((state:any) => state.account);
    const { walletProvider } = useWeb3ModalProvider();
    
    const [pageSet, setPage] = useState(false);
    const [widget_section_html, setWidgetSectionHTML] = useState<any>(null);
    const [widget_section_header_logo, setWidgetHeaderLogo] = useState<any>(null);
    const [widget_section_header, setWidgetSectionHeader] = useState<any>(null);
    const [widget_section_title, setWidgetSectionTitle] = useState<any>(null);
    const [widget_section_subtitle, setWidgetSectionSubtitle] = useState<any>(null);
    const [widget_section_account, setWidgetSectionAccount] = useState<any>(null);

    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [walletNetwork, setWalletNetwork] = useState('CONNECT');
    const [walletNetworkAddress, setWalletNetworkAddress] = useState('Not Connected');

    /* Capture Game ID and Product/Payment ID */
    if(window.location.hash) {
        if(window.location.hash.indexOf("?") >= 0) {
            let paramarr = window.location.href.split("?")[1].split("&");
            for(var j = 0; j < paramarr.length;j++) {
                let paramsplit = paramarr[j].split("=");
                if ((paramsplit[0] == 'gameID')||(paramsplit[0] == 'game')||(paramsplit[0] == 'gameid')||(paramsplit[0] == 'game_id')) {
                    current_game_id = parseInt(paramsplit[1]);
                } else if ((paramsplit[0] == 'pID')||(paramsplit[0] == 'pid')||(paramsplit[0] == 'p')||(paramsplit[0] == 'product')) {
                    current_product_id = paramsplit[1];
                } else if ((paramsplit[0] == 'sID')||(paramsplit[0] == 'sid')||(paramsplit[0] == 'session')||(paramsplit[0] == 'session_id')) {
                    current_session_id = paramsplit[1];
                } else if (paramsplit[0] == 'action') {
                    current_game_action = paramsplit[1];
                }
            }
        }        
    }

    useEffect(() => {
        window.parent.postMessage('','*');
    }, []);

    useEffect(() => {
        // checkWalletBalance()
    }, [props.wcModal]);

    function sendParentMessage(message_type:string) {
        let message_data:any = {};
        if(message_type == 'close') {
            message_data['message_source'] = 'EP';
            message_data['message'] = 'modal_closed';
        } else if(message_type == 'goto_game') {
            message_data['message_source'] = 'EP';
            message_data['message'] = 'goto_game';
        } else if(message_type == 'login') {
            message_data['message_source'] = 'EP';
            message_data['message'] = 'user_login';
            message_data['sessionID'] = current_session_id;
            message_data['userID'] = current_user_id;
            message_data['userName'] = current_user_name;
            message_data['userData'] = login_transaction;
        } else if(message_type == 'logout') {
            message_data['message_source'] = 'EP';
            message_data['message'] = 'user_logout';
            message_data['sessionID'] = current_session_id;
            message_data['userID'] = 0;
            message_data['userName'] = '';
            message_data['userData'] = {};
        } else if(message_type == 'paid') {
            message_data['message_source'] = 'EP';
            message_data['message'] = 'user_paid';
            message_data['sessionID'] = current_session_id;
            message_data['userID'] = current_user_id;
            message_data['userName'] = current_user_name;
            message_data['userData'] = login_transaction;
            message_data['txn'] = payment_transaction;
        }

        window.parent.postMessage(message_data,'*');
    }

    function updatePaymentDetails(txndata:any) {
        payment_transaction = txndata;
    }

    async function getGameAccountInfo() {
        if(accountID > 0) {
            current_user_id = accountID;
        }
        var addData:any = new Object();
        addData.userID = encodeURIComponent(current_user_id);
        addData.gameID = encodeURIComponent(current_game_id);
        addData.sessionID = encodeURIComponent(props.sessionID);
        await axiosInstance.post('easyplay/user/game_account_info/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                let account_info = result.data;
                current_user_name = account_info[0]['user_name'];
                login_transaction = {
                    userEmail:account_info[0]['email_address'],
                    userWallet:account_info[0]['wallet_address'],
                }
                if(current_product_id.length > 0) {
                    setCurrentSection('user_payment');
                } else if (current_game_action == 'logout') {
                    setCurrentSection('user_logout');
                } else {
                    setCurrentSection('user_home');
                    sendParentMessage('login');
                }
            } else if (current_game_action == 'register') {
                setCurrentSection('user_register');
            } else {
                setCurrentSection('login');
            }
        }).catch(error => {
            console.log(error);
            setCurrentSection('login');
        });
    }

    function loadUserAccountNav() {
        setWidgetSectionTitle(current_game_name.toUpperCase());
        $("#ep-main-subtitle").css({'margin-top':'-10px',color:'silver'});
        
        var tmp_html:any = <div id="ep-account-nav" className="col s12 white-text">
                                <div className="row center-align" style={{padding:'0px',marginBottom:'10px',position:'relative',fontSize:'12px'}}>
                                        <span className="exr-light-green-text">{current_user_name.toUpperCase()}</span> // <span className="white-green-link" onClick={logoutUser}>LOGOUT</span>
                                    </div>
                                </div>;

        if (current_game_id == 1) {
            setWidgetSectionTitle(current_game_name.replace("EXR ","").toUpperCase());
        }
        
        setWidgetSectionAccount(tmp_html);
    }

    function loadLoggedInUser(accid:number,walletid:string) {
        current_user_id = accid;
        current_wallet_address = walletid;
        getGameAccountInfo();
    }

    function goToAccountHome() {
        setCurrentSection('user_home');
    }

    function logoutUser() {
        dispatch(setAccountSignOn(0,'',''));
        window.location.reload();
        if(current_product_id.length == 0) {
            sendParentMessage('logout');
        }
    }

    function setCurrentSection(sectionid:string) {
        if(current_game_id == 1) {
            setWidgetSectionHeader(<span className="left" style={{width:'100%',textAlign:'center'}}>
                <img src="https://media.exiledracers.com/global/exr_logo_v2_white_trans.png" height="30px" />
            </span>);
        }
        if(sectionid == 'user_home') {
            loadUserAccountNav();
            var subtitle_text:any = <span>WELCOME TO {current_game_name.toUpperCase()}! YOU'RE NOW LOGGED IN. CLICK THE BUTTON BELOW TO CONTINUE.</span>;
            if(current_game_action == 'register') {
                subtitle_text = <span>WELCOME TO {current_game_name.toUpperCase()}! CLICK THE BUTTON BELOW TO FINISH REGISTRATION.</span>;
            }
            $("#ep-main-subtitle").css({'margin-top':'0px'});
            setWidgetSectionSubtitle(subtitle_text);
            var section_html = <GameHomePage wcModal={props.wcModal} gameID={current_game_id} productID={current_product_id} pageAction={current_game_action}
                                            switchSections={setCurrentSection} sendMessage={sendParentMessage} sessionID={current_session_id} />;
            setWidgetSectionHTML(section_html);
            setTimeout(function(){
                $("#ep-widget-main").fadeIn();
            },1000);
        } else if(sectionid == 'user_payment') {
            loadUserAccountNav();
            var subtitle_text:any = "REVIEW AND CHECKOUT BELOW";
            setWidgetSectionSubtitle(subtitle_text);
            var section_html = <GamePaymentPage wcModal={props.wcModal} gameID={current_game_id} productID={current_product_id} updatePaymentDetails={updatePaymentDetails}
                                                switchSections={setCurrentSection} sendMessage={sendParentMessage} sessionID={current_session_id} />;
            setWidgetSectionHTML(section_html);
            setTimeout(function(){
                $("#ep-widget-main").fadeIn();
            },1000);
        } else if(sectionid == 'user_logout') {
            loadUserAccountNav();
            var subtitle_text:any = <span>YOU'RE CURRENTLY LOGGED IN. CLICK THE BUTTON BELOW TO LOGOUT OF THIS GAME.</span>;
            $("#ep-main-subtitle").css({'margin-top':'0px'});
            setWidgetSectionSubtitle(subtitle_text);
            var section_html = <GameLogoutPage wcModal={props.wcModal} gameID={current_game_id} productID={current_product_id} 
                                            switchSections={setCurrentSection} sendMessage={sendParentMessage} sessionID={current_session_id} />;
            setWidgetSectionHTML(section_html);
            setTimeout(function(){
                $("#ep-widget-main").fadeIn();
            },1000);
        } else if(sectionid == 'user_register') {
            var title_text:any = <>
                                <span className="left" style={{width:'100%',textAlign:'center',marginTop:'-5px'}}>REGISTER TO PLAY</span>
                                </>
            setWidgetSectionTitle(title_text);
            var subtitle_text:any = <span>GET STARTED ON EXR GHOST RIDERS. REGISTER BELOW TO PLAY.</span>;
            setWidgetSectionSubtitle(subtitle_text);
            $("#ep-main-subtitle").css({'margin-top':'-10px'});

            var section_html = <GameRegisterPage  wcModal={props.wcModal} gameID={current_game_id} loadUserAccount={loadLoggedInUser} sessionID={current_session_id} />;
            setWidgetSectionHTML(section_html);
            $("#ep-widget-main").fadeIn();
        } else if(sectionid == 'continue_game') {
            sendParentMessage('goto_game'); 
        } else if(sectionid == 'logout_game') {
            logoutUser(); 
        } else {
            
            
            setWidgetSectionTitle('LOGIN TO PLAY');
            setWidgetSectionSubtitle('LOGIN TO CONTINUE OR SIGNUP FOR A FREE ACCOUNT.');
            $("#ep-main-subtitle").css({'margin-top':'-10px'});

            var section_html = <GameLoginPage wcModal={props.wcModal} gameID={current_game_id} loadUserAccount={loadLoggedInUser} sessionID={current_session_id} />;
            setWidgetSectionHTML(section_html);
            $("#ep-widget-main").fadeIn();
        }
    }

    if(pageSet === false) {
        setPage(true);
        if(accountID > 0) {
            loadLoggedInUser(accountID, accountAddress);
        }else if (current_game_action == 'register') {
            setCurrentSection('user_register');
        } else {
            setCurrentSection('login');
        }
        
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto'}}>
        <div className="row" id="ep-widget" style={{position:'relative',minHeight:'600px',marginBottom:'0px'}}>
            <div id="ep-widget-main" className="col s12 m8 offset-m2 l6 offset-l3 white-text transparent" 
            style={{position:'relative',zIndex:'10',marginTop:'20px',marginBottom:'100px',padding: '0px 30px 0px 40px'}}>
                <div className="col s12" id="widget_header">
                    {widget_section_header}
                </div>
                <div className="col s12" id="widget_title">
                    <div className="row center-align headline-text" style={{marginTop:'5px',fontSize:'24px',marginBottom:'0px'}}>
                        {widget_section_title}
                    </div>
                </div>
                <div className="col s12" id="widget_header">
                    {widget_section_account}
                </div>
                <div className="col s12" id="widget_title_divider">
                    <div className="row" style={{marginTop:'0px'}}>
                        <div className="divider-thin" style={{backgroundColor:"#c1ff72"}}></div>
                    </div>
                </div>
                
                <div className="col s12" id="widget_subtitle">
                    <div id="ep-main-subtitle" className="row center-align" style={{marginTop:'0px',letterSpacing:'2px'}}>
                        {widget_section_subtitle}
                    </div>
                </div>
                
                <div className="col s12 white-text" style={{padding:'10px 0px'}}>
                    {widget_section_html}
                </div>
                
            </div>
            <div className="col s12 white-text" style={{position:'absolute',bottom:'10px',left:'0px'}}>
                <div className="row center-align" style={{marginTop:'0px'}}>
                    <img src="https://media.easyplay.co/global/easyplay_tmp_logo_white.png" style={{height:"40px"}} />
                </div>
            </div>
        </div>
    </div>);
};

export default GameWidget;