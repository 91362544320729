import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits, encodeBytes32String, parseUnits, hexlify  } from 'ethers'

import { clearAccountSignOn } from "../redux/Account/actions";
import {axiosInstance, startPageModals, timestampToText} from "../utils";

import $ from "jquery";

const payment_contract_abi_base_sepolia = require("../utils/abis/payment_base_sepolia_abi.json");
const base_usdc_abi = require('../utils/abis/usdc_base_abi.json');


export function getChainNetworkName(chainID:number) {
    if(chainID == 84532) {
        return 'base_sepolia';
    } else if(chainID == 8453) {
        return 'base';
    } else if(chainID == 1) {
        return 'ethereum';
    } else if(chainID == 1284) {
        return 'moonbeam';
    }
    return '';
}


export async function changeWalletNetwork(wcModal:any, network:string) {
    try {
        if(network == 'base_sepolia') {
            await wcModal.switchNetwork(84532);
        } else if(network == 'base') {
            await wcModal.switchNetwork(8453);
        } else if(network == 'ethereum') {
            await wcModal.switchNetwork(1);
        } else if(network == 'moonbeam') {
            await wcModal.switchNetwork(1284);
        }
    } catch(e) {
        return 'revert';
    }

    return network;
}


export async function getWalletBalance(wcModal:any, walletAddress:string, network:string, currency:string) {
    let walletconnected:any = wcModal.getIsConnected();

    if (!walletconnected) throw Error('User disconnected');

    let current_provider:any = wcModal.getWalletProvider();
    let currency_address:any;
    let currency_abi:any = [];
    let currency_decimals = 18;
    let final_balance:any = 0;
    let final_currency = 'native';

    if (currency == 'USDC') {
        final_currency = currency;
        currency_decimals = 6;
        if(network == 'base') {
            currency_address = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
            currency_abi = base_usdc_abi;
        } else if(network == 'moonbeam') {
            currency_decimals = 9;
        }
    }

    if(current_provider !== undefined) {
        let currency_balance:any = 0;
        const ethersProvider = new BrowserProvider(current_provider);
        if(final_currency == 'native') {
            currency_balance = await ethersProvider.getBalance(walletAddress);
        } else {
            const signer = await ethersProvider.getSigner();
            const currency_contract = new Contract(currency_address, currency_abi as any, signer);
            currency_balance = await currency_contract.balanceOf(walletAddress);
        }
        
        final_balance = formatUnits(currency_balance, currency_decimals);
    }

    return final_balance;
}


export async function makeOnchainPayment(wcModal:any, network:string, currency:string, amount:number, paymentID:string) {
    let final_info:any = {};
    final_info['status'] = 'failed';

    /* CHECK IF WALLET CONNECT IS CONNECTED */
    let walletconnected = wcModal.getIsConnected();
    if (!walletconnected) throw Error('User disconnected');
    let current_provider = wcModal.getWalletProvider();

    /* SETUP  CONTRACT AND CURRENCY ADDRESS AND ABI */
    let payment_contract_address:any = '';
    let payment_contract_abi:any = '';
    let currency_address:any = '';
    let currency_decimals = 18;

    if(network == 'base') {
        payment_contract_address = '0xee7eb71bd40779f3400eaee37ed32150cc41faf3';
        payment_contract_abi = base_usdc_abi;
        if(currency == 'USDC') {
            currency_decimals = 6;
            currency_address = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
        } else {
            currency_address = ''
        }
    } else if(network == 'base_sepolia') {
        payment_contract_address = '0xee7eb71bd40779f3400eaee37ed32150cc41faf3';
        payment_contract_abi = payment_contract_abi_base_sepolia;
        currency_address = '';
    } 

    /* START TRANSACTION IF CONNECTED AND PROPER PAYMENT INFORMATION PASSED */
    let payment_transaction:any = null;
    if((current_provider !== undefined)&&(payment_contract_address.length > 0)&&(amount > 0)) {
        const ethersProvider = new BrowserProvider(current_provider)
        const signer = await ethersProvider.getSigner()
        const payment_contract = new Contract(payment_contract_address, payment_contract_abi as any, signer);

        /* SETUP BYTES32 RECEIPT TO PASS */
        let formatted_receipt:any = encodeBytes32String(paymentID);
        final_info['receipt'] = formatted_receipt;

        /* SETUP FINAL AMOUNT FORMAT */
        let final_amount = parseUnits(amount.toString(),currency_decimals);
        
        /* SEND NATIVE CHAIN CURRENCY IF CURRENCY NOT SPECIFIED */
        if(currency_address.length > 0) {
            try{
                payment_transaction = await payment_contract.depositERC20({
                    tokenAddress:currency_address,
                    amount:final_amount,
                    receipt:formatted_receipt
                });
                final_info['status'] = 'success';
                final_info['transaction_hash'] = payment_transaction['hash'];
            } catch(e:any) {
                final_info['status'] = 'rejected';
            }
        } else {
            try{
                payment_transaction = await payment_contract.depositNative(formatted_receipt, {value: final_amount});
                final_info['status'] = 'success';
                final_info['transaction_hash'] = payment_transaction['hash'];
            } catch(e:any) {
                final_info['status'] = 'rejected';
            }
            
        }
    }

    return final_info;
}