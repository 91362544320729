export const setReady = () => {
  console.log("setting ready");
  return async (dispatch) => {
    dispatch({
      type: "SET_IS_READY",
    });
  };
};

export const setLoginWallet = (walletID) => {
  localStorage.setItem('epWallet', walletID);
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: walletID,
    });
  };
};

export const setAccountSignOn = (accountID, loginToken, loginTime) => {
  // localStorage.setItem('epWalletID', walletID);
  let tokenval = accountID;
  localStorage.setItem('epTokenID', loginToken);
  localStorage.setItem('epTokenTime', loginTime);
  localStorage.setItem('epTokenVal', tokenval);
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_SIGNON",
      payload: {account_id: accountID, token: loginToken, token_time: loginTime},
    });
  };
};

export const clearAccountSignOn = () => {
  // localStorage.setItem('epWalletID', walletID);
  localStorage.removeItem('epTokenID');
  localStorage.removeItem('epTokenTime');
  localStorage.removeItem('epTokenVal');
  localStorage.removeItem('epWallet');
  return async (dispatch) => {
    dispatch({
      type: "SET_ACCOUNT_SIGNON",
      payload: {account_id: 0, token: '', token_time: ''},
    });
    dispatch({
      type: "SET_ACCOUNT_ADDRESS",
      payload: '',
    });
  };
};