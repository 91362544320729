import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";

import appReducer from "./App/reducer";
import accountReducer from "./Account/reducer";
import authReducer from "./Auth/reducer";

const reducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  auth: authReducer,
});

const composeEnhancers = composeWithDevTools({
  realtime: true,
  name: "EasyPlay",
  hostname: "localhost",
  port: 3000, // the port your remotedev server is running at
});

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;