import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {setAccountSignOn } from "../../../redux/Account/actions";
import {validateEmailText, sendWaitroomEmailCode, checkLoginEmailCode } from "../../../utils/signon";
import {axiosInstance } from "../../../utils";
import $ from "jquery";

let current_user_id = 0; 
let current_login_status = '';
export const SplashMain = (props:any) => {
    const dispatch:any = useDispatch();
    const { page_loaded }= useSelector((state:any) => state.app);
    const { racerID, userID, accountAddress } = useSelector((state:any) => state.account);

    const [pageSet, setPage] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState("");
    const [current_wallet, setCurrentWallet] = useState("");
    const [registration_level, setRegistrationlevel] = useState('REGISTRATION COMPLETE');

    /* EXR Event ID */
    var exreventid = 5;
    let signup_user_id = 0;

    useEffect(() => {
        if((userID > 0)&&(userID !== undefined)) {
            current_user_id = userID;
            signup_user_id = userID;
        }
    }, []);

    async function submitEmailAddress() {
        var useremail = (document.getElementById("userEmailAddress") as HTMLInputElement).value.trim();

        if(useremail.length < 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(useremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else {
            $("#email-send-button").hide();
            $("#email-sent-loading").show();
            if(userID > 10000) {
                showEmailLoggedIn();
            } else {
                let email_send = await sendWaitroomEmailCode(useremail,'');
                if(email_send['status'] == 'email_sent') {
                    current_login_status = email_send['user_status'];
                    setEmail(useremail);
                    showEmailSendSent();
                } else {
                    showEmailSendReset();
                    alert('Something Went Wrong. Update and Try Again.');
                }
            }
        }
    }

    async function submitEmailCode() {
        var useremail = (document.getElementById("userEmailAddress") as HTMLInputElement).value.trim();
        var emailcode = (document.getElementById("userEmailCode") as HTMLInputElement).value.trim();

        if(useremail.length <= 3) {
            alert('Add a Valid Email Address');
        } else if (validateEmailText(useremail) == 'fail') {
            alert('Add a Valid Email Address');
        } else if (emailcode.length != 6) {
            alert('Add a Valid Email Code');
        } else {
            $("#code-send-button").hide();
            $("#code-confirmed-loading").show();
            let email_code_check:any = await checkLoginEmailCode(useremail,emailcode,current_login_status);
            if(email_code_check) {
                if(email_code_check['status'] == 'email_code_expired') {
                    showEmailCodeReset();
                    alert('Your Confirmation Code Expired. Register Again To Get a New Code.');
                } else if(email_code_check['status'] == 'email_code_wrong') {
                    showEmailCodeReset();
                    alert('Invalid Code. Check Your Code and Enter it Again');
                } else if (email_code_check['status'] == 'confirmed_waitroom') {
                    // New User - Add to Users Table
                    let userid = email_code_check['login_info']['userid'];
                    let passid = email_code_check['login_info']['passid'];
                    let logintime = email_code_check['login_info']['logintime'];
                    let logintoken = email_code_check['login_info']['logintoken'];

                    // Login to Site
                    current_user_id = userid;
                    signup_user_id = userid;
                    dispatch(setAccountSignOn(userid,logintoken,logintime));

                    // New User - Approve Code
                    showEmailCodeApproved();
    
                } else if (email_code_check['status'] == 'confirmed_existing') {
                    // Existing User - Approve Code
                    let userid = email_code_check['login_info']['userid'];
                    let passid = email_code_check['login_info']['passid'];
                    let logintime = email_code_check['login_info']['logintime'];
                    let logintoken = email_code_check['login_info']['logintoken'];
    
                    // Login to Site
                    current_user_id = userid;
                    signup_user_id = userid;
                    dispatch(setAccountSignOn(userid,logintoken,logintime));
    
                    showEmailCodeApproved();
                } else {
                    showEmailCodeReset();
                    alert('Whoops! Something Went Wrong. Update and Try Again');
                }
            }
        }
    }

    function showEmailSendSent() {
        setTimeout(function(){
            $("#register-email").hide();
            $("#email-sent-text").fadeIn();
            $("#register-email-code").fadeIn();
        },3000);
    }

    function showEmailSendReset() {
        $("#email-send-button").show();
        $("#email-sent-loading").hide();
        $("#email-sent-button").hide();
    }

    function showEmailLoggedIn() {
        setTimeout(function(){
            $("#email-send-button").hide();
            $("#email-sent-loading").hide();
        },3000);
    }

    function showEmailCodeApproved() {
        setTimeout(function(){
            $("#register-email-code").hide();
            $("#email-sent-text").hide();

            $("#email-confirmed-text").fadeIn();
            $("#register-wallet").fadeIn();
        },3000);
    }

    function showConnectedWalletForm() {
        $("#connected-wallet").fadeIn();
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    }

    function showWalletSaved() {

        setTimeout(function(){
            $("#wallet-save-loading").hide();
            $("#register-email-code").hide();
            $("#email-sent-text").hide();
            $("#register-wallet").hide();
            $("#connected-wallet").hide();
        
            $("#email-confirmed-text").fadeIn();
            $("#wallet-confirmed-text").fadeIn();
            $("#register-complete-text").fadeIn();

            //loadEventUserInfo();
        },3000);
        
    }

    function showEmailCodeReset() {
        $("#code-send-button").show();
        $("#code-confirmed-loading").hide();
        $("#code-confirmed-button").hide();
    }

    function showRegistrationForm() {
        $("#register-button").hide();
        $("#home-value-props").hide();
        
        $("#home-value-props2").fadeIn();
        $('#register-email').fadeIn();
        $("#register-button-subheadline").hide();
    }

    if(pageSet === false) {
        setPage(true);
        // loadLeagueUserInfo();
    }

    window.addEventListener("hashchange",
    function(){ 
        window.location.reload(); 
    }, false);

    return(<div style={{position:'relative',width:'auto',height:'auto',overflowY:'hidden',backgroundImage:'url(css/images/dune_wallpaper_gradient.png)',backgroundSize:'cover'}}>
        <div className="row" id="main-landing-page" style={{position:'relative',width:'100%',minHeight:'100vh',marginBottom:'0px'}}>
            <div className="col s12 transparent" style={{position:'relative',zIndex:'10',marginTop:'40px'}}>
                <div id="splash-main-buttons">
                    <div className="col s12 m6">
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 m10 offset-m1 white-text event-register-large-headline-mid">
                                <img src="https://media.easyplay.co/global/easyplay_tmp_logo_white.png" style={{height:"70px"}} />
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 m10 offset-m1 white-text">
                                <div className="headline-text event-register-large-headline-mid white-text" style={{fontSize:'40px',lineHeight:'1.25'}}>
                                    ONE <span className="purple-gradient-text">PASS</span> TO<br/>PLAY THEM ALL
                                </div>
                            </div>
                        </div>
                        <div id="home-value-props" className="row" style={{marginTop:'20px'}}>
                            <div className="col s10 offset-s1 l8 offset-l1 white-text show-on-medium-and-up hide-on-small-only">
                                <div className="subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'20px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    The easiest way to access your<br/>gaming adventures on and off chain.
                                </div> 
                            </div>
                            <div className="col s10 offset-s1 l8 offset-l1 white-text hide-on-med-and-up show-on-small-only">
                                <div className="subheadline-text event-register-subheadline-small center-align"
                                    style={{fontSize:'20px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    The easiest way to access your gaming adventures on and off chain.
                                </div> 
                            </div>
                        </div>
                        <div id="home-value-props2" className="row" style={{marginTop:'30px',display:'none'}}>
                            <div className="col s10 offset-s1 l8 offset-l1 white-text">
                                <div className="left subheadline-text event-register-subheadline-small"
                                    style={{fontSize:'18px',textTransform:'uppercase',color:'silver',letterSpacing:'2px'}} >
                                    REGISTER TO GET ACCESS TO THE EASYPLAY PASS. CONFIRM YOUR <span className="exr-base-blue-light-text">EMAIL</span> TO JOIN.
                                </div> 
                            </div>
                        </div>
                        <div className="row register-sections" style={{marginTop:'0px',paddingTop:'0px',marginBottom:'0px'}}>
                            <div id="email-sent-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined grey-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left"
                                        style={{flex:1,fontSize:'18px',color:'silver',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CODE SENT:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                            <div id="email-confirmed-text" style={{display:'none'}}>
                                <div className="col s11 offset-s1 m10 offset-m1 white-text" style={{marginTop:'20px',display:'flex',flexDirection:'row'}}>
                                    <div className="subheadline-text left"
                                        style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}> 
                                        <span className="left material-symbols-outlined exr-base-blue-light-text">
                                            check_circle
                                        </span>  
                                    </div>
                                    <div className="subheadline-text left exr-base-blue-light-text"
                                        style={{flex:1,fontSize:'18px',letterSpacing:'2px',marginLeft:'10px',marginTop:'-1px'}}> 
                                            <b>EMAIL CONFIRMED:&nbsp;&nbsp;</b><br/>
                                            <span style={{fontSize:'18px',color:'silver',letterSpacing:'2px'}}>
                                                {email}
                                            </span>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div id="register-email" className="row register-sections" style={{display:'none',marginTop:'20px',marginBottom:'0px'}}>
                            
                            <div id="register-email-form-title" className="col s10 offset-s1 l8 offset-l1 white-text register-email-forms">
                                <span className="left subheadline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}}>
                                    STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%',marginLeft:'0px'}}>
                                    ENTER EMAIL ADDRESS
                                </span>
                            </div>
                            <div id="register-email-form" className="col s10 offset-s1 l8 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Address to Register">
                                    <input id="userEmailAddress" className="black-bubble-input-round" 
                                        style={{fontSize:'20px',marginLeft:'0px',margin:'0px',marginRight:'-15px'}} />
                                </span>
                                <span id="email-send-button" className="round-border-button right black white-text purple-gradient" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailAddress()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="email-sent-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div id="register-email-code" className="row register-sections" style={{display:'none',marginTop:'30px',marginBottom:'0px'}}>
                            <div id="confirm-email-form-title" className="col s10 offset-s1 l8 offset-l1 white-text">
                                <span className="left subheadline-text event-register-formheader" 
                                        style={{width:'100%',marginLeft:'0px',fontSize:'12px',color:'silver'}} title="Register For Exiled Racers">
                                        STEP 1 / 2
                                </span>
                                <span className="left headline-text event-register-formheader" 
                                    style={{width:'100%'}} title="Register For Exiled Racers">
                                    ENTER EMAIL CODE
                                </span>
                            </div>
                            <div id="confirm-email-form" className="col s10 offset-s1 l8 offset-l1" style={{marginBottom:'20px'}}>
                                <span className="left" style={{width:'100%',fontSize:'20px',lineHeight:'1em !important',height:'calc(1em + 10px)'}} title="Enter Email Code to Confirm">
                                    <input id="userEmailCode" className="black-bubble-input-round" 
                                        style={{width:'100%',fontSize:'20px',borderColor:'black'}} />
                                </span>
                                <span id="code-send-button" className="round-border-button right black white-text purple-gradient" 
                                    style={{marginTop:'20px',marginLeft:'0px',marginRight:'0px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'25px',padding:'10px 20px 12px 30px',height:'40px',position:'relative'}} 
                                    title="Register For Exiled Racers" onClick={()=>submitEmailCode()}>
                                    <span className="material-symbols-outlined white-text" style={{position:'absolute',left:'5px',top:'2.5px',fontSize:'35px'}}>
                                        expand_circle_right
                                    </span>
                                    <span className="subheadline-text-mono" style={{margin:"2px 5px 0px 20px",fontSize:'18px',letterSpacing:'1px'}}>
                                        SUBMIT
                                    </span>
                                </span>
                                <span id="code-confirmed-loading" className="preloader-wrapper small active event-register-formbutton right" 
                                    style={{display:'none',height:'30px',width:'30px',marginTop:'23px'}}>
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                        <div id="register-button" className="row" style={{marginTop:'30px'}}>
                            <div className="col s12 m10 offset-m1 event-register-large-headline-mid">
                                <a className="round-border-button center-align black white-text purple-gradient" 
                                    style={{display:'inline-block', marginLeft:'5px',marginRight:'5px',width:'auto',border:'0px solid white',fontSize:'20px',borderRadius:'30px',padding:'20px 50px 20px 50px',height:'60px',position:'relative'}} 
                                    title="Register For Exiled Racers" href="mailto:hey@easyplay.co">
                                    <span className="subheadline-text-mono" style={{fontSize:'18px',letterSpacing:'1px'}}>
                                        REQUEST ACCESS
                                    </span>
                                </a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col s12 m6">

                        {/* <img src="./css/images/gamegrid_temp.png" style={{background:'white',marginLeft:'-15%',width:'110%',marginTop:'20px'}} /> */}
                    </div>
                </div>
            </div>
        </div>
        
        
        <div className="row howtoplay" id="event-landing-page-9" style={{display:'block',position:'absolute',bottom:'0px',left:'0px',minWidth:'100vw',marginBottom:'0px'}}>
            <div className="col s12 m10 offset-m1">
                <div className="row white-text show-on-med-and-up hide-on-small-only" style={{padding:'0px',marginTop:'20px'}}>
                    {/* <span className="left" style={{marginRight:'20px'}}>
                        Contact
                    </span> */}
                    <span className="left" style={{fontSize:'12px',color:'#606060',marginLeft:'-20px'}}>
                       
                        &copy; {new Date().getFullYear()}, Exiled Studios Inc. All Rights Reserved
                    </span>
                </div>
                <div className="row white-text center-align hide-on-med-and-up show-on-small-only" style={{padding:'0px',marginTop:'20px'}}>
                    {/* <span className="left" style={{marginRight:'20px'}}>
                        Contact
                    </span> */}
                    <span style={{fontSize:'12px',color:'#606060'}}>
                       
                        &copy; {new Date().getFullYear()}, Exiled Studios Inc. All Rights Reserved
                    </span>
                </div>
            </div>
        </div>
    </div>);
};

export default SplashMain;