const initialState = {
  hasAccount: false,
  loggedIn: false,
  status: null,
  accountAddress: localStorage.getItem('epWallet')||'',
  accountID: (localStorage.getItem('epTokenVal')? parseInt(localStorage.getItem('epTokenVal')) : 0),
  loginTime: localStorage.getItem('epTokenTime')||'',
  loginToken: localStorage.getItem('epTokenID')||'',
  infoboxHowTo: 1
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCOUNT_ADDRESS":
      return {
        ...state,
        accountAddress: action.payload,
      };

    case "SET_STATUS":
      return {
        ...state,
        status: action.payload,
      };
    
    case "SET_ACCOUNT_SIGNON":
        return {
          ...state,
          accountID: action.payload.account_id,
          loginToken: action.payload.token,
          loginTime: action.payload.token_time,
        };

    default:
      return state;
  }
};

export default accountReducer;
