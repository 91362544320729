import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { clearAccountSignOn } from "../../../redux/Account/actions";
import {axiosInstance, startPageModals, timestampToText} from "../../../utils";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import $ from "jquery";

import {default as StripePaymentElement} from '../StripePaymentElement';

// stripe test publishable API key.
const stripePromise = loadStripe("pk_test_51MF1vbD9WlXbyG8XI9RQlt0fwzMAt68juGNfqoT2uTzAnozzBpf5vNymQ2dyoOP57kwbFqa4dHWH1HvsESIVsOiS00D3xxGnVS");

const StripePaymentForm = (props:any) => {
    const dispatch = useDispatch();
    const { accountID, accountAddress } = useSelector((state:any) => state.account);

    const [formSet, setForm] = useState(false);
    const [stripeFormHTML,setStripeFormHTML] = useState(null);
    const [stripeClientSecret, setStripeClientSecret] = useState(null);

    useEffect(() => {
        
    }, []);

    /* CREATE STRIPE FORM SESSION */
    function createStripeForm(amount:any,currency:any) {
        if(amount < 1) {
            amount = 1;
        }
        var non_decimal_amount = amount*100;
        var addData:any = new Object();
        addData.item = encodeURIComponent('free'+non_decimal_amount);
        axiosInstance.post('easyplay/user/create_card_payment/',addData).then(result => {
            if(Object.keys(result.data).length > 0) {
                var session_details = result.data[0]; 
                if(session_details['status'] == 'success') {
                    var clientSecret = session_details['checkout']['clientSecret'];
                    setStripeClientSecret(clientSecret);
                    loadStripeForm(clientSecret,amount,currency);
                } else {
                    console.log('Stripe Issue ',session_details['checkout']['error']);
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    /* LOAD STRIPE FORM HTML */
    function loadStripeForm(clientSecret:any,amount:any,currency:any) {
        if(clientSecret) {
            const appearance:any = {
                theme: 'night',
                variables: {
                    colorPrimary: 'white',
                    colorBackground: 'black',
                    colorText: 'white',
                    colorDanger: '#FFF367',
                    fontFamily: 'Helvetica',
                    spacingUnit: '5px',
                    borderRadius: '10px',
                    borderSize:'0px'
                    // See all possible variables below
                  },
                rules: {
                    '.Input': {
                        border: '1px solid silver',
                        padding: '10px'
                    },
                    '.AccordionItem': {
                        border:'0px',
                        borderTop:'1px solid #3A3A3A',
                        borderRadius:'0px'
                    },
                    '.TabLabel': {
                        textTransform: 'uppercase'
                    },
                    '.Label': {
                        textTransform: 'uppercase',
                        fontSize:'12px'
                    }
                }
            };
            let tmp_options:any = {
                clientSecret,
                appearance,
            }

            let tmp_html:any = <Elements options={tmp_options} stripe={stripePromise}>
                <StripePaymentElement wcModal={props.wcModal} gameID={props.gameID} productID={props.productID} 
                                                amount={props.amount} currency={props.currency} savePayment={props.savePayment} goBackCall={props.goBackCall} />
            </Elements>;

            setStripeFormHTML(tmp_html);
        }
    }

    if(formSet === false) {
        setForm(true);
        createStripeForm(props.amount,props.currency);
    }

    return (
        <div style={{position:'relative',width:'auto',height:'auto'}}>
            {stripeFormHTML}
        </div>
    );
}

export default StripePaymentForm;