const initialState = {
  metamaskConnected: "",
  isReady: false,
  loading: false,
  signup_loading: false,
  game_loading: false,
  page_loaded: false, 
  admin_accounts: [1],
  live_hosts: ['www.exiledracers.com','training.exiledracers.com','harumicup.exiledracers.com']
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PAGE_LOADED":
      return {
        ...state,
        page_loaded: true,
      };

    case "SET_IS_READY":
        return {
          ...state,
          isReady: true,
        };
    
    case "SIGNUP_LOADING":
      return {
        ...state,
        signup_loading: action.payload,
      };
      
    case "GAME_LOADING":
      return {
        ...state,
        game_loading: action.payload,
      };
    
    default:
      return state;
  }
};

export default appReducer;
